import React from 'react'

export const AttendanceMock = () => {
  return (
    <div className="attendance-container">
      <p>
        Entre em contato conosco para solicitações, dúvidas, reclamações ou
        sugestões diretamente pelo e-mail:
      </p>
      <p>
        <strong>
          <a href="mailto: faleconosco@superpao.com.br">
            faleconosco@superpao.com.br
          </a>
        </strong>
      </p>
      <p>
        Para enviar currículo acesse este link:
        <br />
        <a
          href="https://superpao.econtrata.com.br"
          target="_blank"
          rel="noreferrer"
        >
          https://superpao.econtrata.com.br
        </a>
      </p>
    </div>
  )
}
